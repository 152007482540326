<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Kegiatan</h3>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <button type="button" class="btn btn-primary mb-2" size="sm" style="background-color:#030A8C; border-color: #030A8C" v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Tambah</button>
                        <div>
                            <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th style="width:350px;">Agenda</th>
                                          <th>Tahun</th>
                                          <th>Tanggal Mulai</th>
                                          <th>Tanggal Selesai</th>
                                          <th>Status</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in kegiatan" :key="row.id">
                                          <td></td>
                                          <td>{{row.agenda}}</td>
                                          <td>{{row.tahun}}</td>
                                          <td>{{row.tanggal_mulai}}</td>
                                          <td>{{row.tanggal_selesai}}</td>
                                          <td>{{row.status_sistem}}</td>
                                          <td>
                                              <div style="width:150px">
                                                  <b-button data-toggle="modal" data-target="#editkegiatan"
                                                    variant="success"
                                                    size="sm"
                                                    style="backgorund-color:#66A626"
                                                    
                                                    @click="getperkegiatan(row.id)"
                                                >
                                                    <i class="flaticon-edit"></i>Ubah
                                                </b-button>
                                                &nbsp;
                                             <b-button @click="deletekegiatan(row.id)" size="sm" variant="danger"><i class="flaticon2-trash"></i> Hapus</b-button>
                                              </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Tambah -->
            <b-modal
                v-model="show"
                id="modal-tambah"
                ref="modal"
                size="md"
                title="Tambah Kegiatan"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahkegiatan">
                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Agenda</label>
                                        <div class="col-lg-8">
                                            <input type="text" v-model="agenda" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-4">Status Sistem</label>
                                        <div class="col-8">
                                            <b-form-select v-model="status_sistem" :options="status"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-4">Status Sistem PK</label>
                                        <div class="col-8">
                                            <b-form-select v-model="status_sistem_pk" :options="status"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tanggal Mulai</label>
                                        <div class="col-lg-8">
                                            <b-form-datepicker v-model="tanggal_mulai" class="mb-2"></b-form-datepicker>
                                
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tanggal Selesai</label>
                                        <div class="col-lg-8">
                                            <b-form-datepicker v-model="tanggal_selesai" class="mb-2"></b-form-datepicker>
                                
                                        </div>
                                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahkegiatan"
                    >
                        Kirim
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>


                <!-- modal edit kegiatan -->
                <div class="modal fade" id="editkegiatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Data Kegiatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Agenda</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="getkegiatan.agenda" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Status Sistem</label>
                        <div class="col-8">
                            <b-form-select v-model="getkegiatan.status_sistem" :options="status"></b-form-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Status Sistem PK</label>
                        <div class="col-8">
                            <b-form-select v-model="getkegiatan.status_sistem_pk" :options="status"></b-form-select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Tanggal Mulai</label>
                        <div class="col-lg-8">
                            <b-form-datepicker v-model="getkegiatan.tanggal_mulai" class="mb-2"></b-form-datepicker>
                
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Tanggal Selesai</label>
                        <div class="col-lg-8">
                            <b-form-datepicker v-model="getkegiatan.tanggal_selesai" class="mb-2"></b-form-datepicker>
                
                        </div>
                    </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editkegiatan(getkegiatan.id)" style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
export default {
  name: "dashboard",
  data() {
      return {
        user: JSON.parse(localStorage.getItem('user')),
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        show:false,
        test:'hai',
        kegiatan:[],
        search: '',
        agenda:'',
        status_sistem: 'Buka',
        status_sistem_pk: 'Buka',
        status: [
         { value: 'Buka', text: 'Buka' },
         { value: 'Tutup Sementara', text: 'Tutup Sementara' },
         { value: 'Tutup Permanen', text: 'Tutup Permanen' },
       ],
       tanggal_mulai:'',
       tanggal_selesai:'',
       getkegiatan:[],
       json_fields: {
        Agenda: "agenda",
        Tahun: "tahun",
        
        },
      }
  },
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kegiatan" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      if (this.user.id_role === 'super_user') {
            $('#example').DataTable().destroy();
                this.loaddata()
        }else {
            this.$router.push({ name: "dashboard" })
        }
    },
    loaddata(){
            axios.get(this.url+"/tanggal_kegiatan", {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.kegiatan = response.data.data;
                this.initDatatable();
            }).catch(error=>{
                console.log(error)
                return error
                // this.loaddata()
                });

            
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    resetModal() {
        this.agenda = ''
        this.status_sistem = 'Buka'
        this.tanggal_mulai=''
        this.tanggal_selesai=''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.tambahkegiatan()
      },
      tambahkegiatan() {
        const agenda = this.agenda;
        const status_sistem = this.status_sistem;
        const status_sistem_pk = this.status_sistem_pk;
        const tanggal_mulai = this.tanggal_mulai;
        const tanggal_selesai = this.tanggal_selesai;
        axios
            .post(
            this.url+"/tanggal_kegiatan",
            {
                agenda,
                status_sistem,
                tanggal_mulai,
                tanggal_selesai,
                status_sistem_pk
            },
            {
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },
    getperkegiatan(id){
        axios.get(this.url+`/tanggal_kegiatan?id=${id}`, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            // axios.get(`http://penkin-penilaian.mysurvey.id/sdm/?filter=idpm,=,`+this.idpm+`;bagian,=,Kepala Dinas`).then(response => {
            this.getkegiatan = response.data.data;
            console.log(response.data.data)
            console.log('getkegiatan')
        });
    },
    handleEdit(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.editsdm()
      },
    editkegiatan(id) {
        axios
            .put(
            this.url+`/tanggal_kegiatan/${id}`,
            {
                // idptsp,
                agenda:this.getkegiatan.agenda,
                tanggal_mulai:this.getkegiatan.tanggal_mulai,
                tanggal_selesai:this.getkegiatan.tanggal_selesai,
                status_sistem:this.getkegiatan.status_sistem,
                status_sistem_pk:this.getkegiatan.status_sistem_pk,
            }
            ,{
                headers:{
                "xth": this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
    },
    deletekegiatan(id){
        axios
            .delete(
            this.url+`/tanggal_kegiatan/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
  }
};
</script>
